<template>
  <section id="pricing">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2><span :class="(data.global['headerIcons']) ? 'pr-3' : 'pr-0'"><b-icon v-if="data.global['headerIcons']" icon="cash-stack" class="text-primary" aria-hidden="true"></b-icon></span>{{ data.json.title }}</h2>
          <p v-html="data.json['paragraph']"></p>
        </div>
      </div>
      <div class="row">
        <div class="">

        </div>
        <b-card-group deck class="col-12">
          <b-card
              v-for="(card, index) in data.json['cards']" :key="index"
              :title="card.title"
              :sub-title="card['subtitle']"
              :img-src="`${publicPath}/assets/${data.portfolioName}/pricing${index+1}.jpg`"
              :img-alt="card.img['alt']"
              img-top
              tag="article"
              style="max-width: 25rem;"
              class="mb-3"
          >
            <b-card-text>
              {{ card.body }}
            </b-card-text>
          </b-card>
        </b-card-group>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FAQ',
  data () {
    return {
      publicPath: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    data () {
      const cJson = this.$webData.components.find((e) => e.name === 'Pricing');
      const portfolioName = this.$portfolioName;
      return {
        json: cJson,
        global: this.$webData.global,
        portfolioName: portfolioName
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container {
  .row {
    padding-top: 0;
  }
}
</style>
